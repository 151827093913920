var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.data.lotteryId == 2 ? 'lottery-kh' : 'lottery-vn'},[_c('result-menu'),_c('b-card',[_c('h3',[_vm._v(_vm._s(_vm.moment(_vm.data.date).locale(_vm.$i18n.locale).format("LL")))]),_c('b-row',{staticClass:"betting-form-container mb-2"},[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('field.date')}},[_c('n-date-picker',{attrs:{"disable-type":"after"},model:{value:(_vm.data.date),callback:function ($$v) {_vm.$set(_vm.data, "date", $$v)},expression:"data.date"}})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('field.lotteryName')}},[_c('b-form-radio-group',{staticClass:"button-checkbox",attrs:{"name":"lottery","button-variant":"outline-primary","buttons":""},model:{value:(_vm.data.lotteryId),callback:function ($$v) {_vm.$set(_vm.data, "lotteryId", $$v)},expression:"data.lotteryId"}},_vm._l((_vm.lotteries),function(lottery,index){return _c('b-form-radio',{key:("lottery-" + index + "-" + (lottery.id)),attrs:{"value":lottery.id,"buttons":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.locale == "en" ? lottery.name : lottery.nameKh)+" ")])])}),1)],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('field.lotteryTime')}},[_c('b-form-radio-group',{staticClass:"button-checkbox",attrs:{"name":"time","button-variant":"outline-primary","buttons":""},model:{value:(_vm.data.lotteryTimeId),callback:function ($$v) {_vm.$set(_vm.data, "lotteryTimeId", $$v)},expression:"data.lotteryTimeId"}},_vm._l((_vm.lotteryTimes),function(time){return _c('b-form-radio',{key:("time-" + (time.id)),attrs:{"value":time.id,"buttons":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.moment(time.name, "HH:mm") .locale(_vm.$i18n.locale) .format("h:mm a"))+" ")])])}),1)],1)],1)],1),_c('validation-observer',{ref:"resultForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('n-form-confirmation',{key:"resultForm",attrs:{"form":_vm.$refs.resultForm,"disabled":invalid},on:{"submit":_vm.submit}},[_vm._l((_vm.channels),function(channel){return _c('b-row',{key:("channel-" + (channel.post)),staticClass:"result-table mb-2"},[_c('b-col',{attrs:{"cols":"4","md":"3","lg":"2"}},[_c('div',{staticClass:"result result-head"},[_vm._v(" "+_vm._s(channel.post)+" ")])]),_c('b-col',{attrs:{"cols":"8","md":"9","lg":"10"}},[_c('b-row',_vm._l((channel.results),function(result,index){return _c('b-col',{key:("result-" + (channel.post) + "-" + index),staticClass:"result-grid",attrs:{"md":_vm.calculateGird(channel.results.length)}},[_c('ValidationProvider',{attrs:{"rules":("min_value:0|min:" + (result.digit) + "|max:" + (result.digit)),"vid":("number2-" + (channel.post)),"name":(channel.post == 'Lo' ? index + 1 + '. ' : '') +
                    _vm.$t(("field." + (result.digit) + "DigitNumber"))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0],"state":errors.length < 1,"label":(channel.post == 'Lo' ? index + 1 + '. ' : '') +
                      _vm.$t(("field." + (result.digit) + "DigitNumber"))}},[_c('n-number-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"name":("number2-" + (channel.post)),"disabled":_vm.data.isPublish,"placeholder":(channel.post == 'Lo' ? index + 1 + '. ' : '') +
                        _vm.$t(("field." + (result.digit) + "DigitNumber"))},model:{value:(result.result),callback:function ($$v) {_vm.$set(result, "result", $$v)},expression:"result.result"}})],1)]}}],null,true)})],1)}),1)],1)],1)}),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(
                _vm.$can('update', 'define-result') && _vm.checkCanPublishResult()
              )?_c('n-button-loading',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"type":"button","variant":"success","loading":_vm.loading},on:{"submit":_vm.publishResult}},[_vm._v(" "+_vm._s(_vm.$t("button.publishResult"))+" ")]):_vm._e(),(
                _vm.$can('update', 'define-result') && _vm.data.id && _vm.data.isPublish && _vm.data.date == _vm.today
              )?_c('n-button-loading',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"button","variant":"danger","loading":_vm.loading},on:{"submit":_vm.cancelResult}},[_vm._v(" "+_vm._s(_vm.$t("button.cancelResult"))+" ")]):_vm._e(),(_vm.$can('update', 'define-result') && !_vm.data.isPublish)?_c('n-button-loading',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"submit","variant":"primary","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t("button.saveChanges"))+" ")]):_vm._e()],1)],1)],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }