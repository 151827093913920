<template>
  <div :class="data.lotteryId == 2 ? 'lottery-kh' : 'lottery-vn'">
    <result-menu></result-menu>

    <b-card>
      <h3>{{ moment(data.date).locale($i18n.locale).format("LL") }}</h3>

      <b-row class="betting-form-container mb-2">
        <b-col lg="12">
          <b-form-group :label="$t('field.date')">
            <n-date-picker v-model="data.date" disable-type="after" />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group :label="$t('field.lotteryName')">
            <b-form-radio-group
              class="button-checkbox"
              v-model="data.lotteryId"
              name="lottery"
              button-variant="outline-primary"
              buttons
            >
              <b-form-radio
                :value="lottery.id"
                v-for="(lottery, index) in lotteries"
                :key="`lottery-${index}-${lottery.id}`"
                buttons
              >
                <span>
                  {{ $i18n.locale == "en" ? lottery.name : lottery.nameKh }}
                </span>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group :label="$t('field.lotteryTime')">
            <b-form-radio-group
              class="button-checkbox"
              v-model="data.lotteryTimeId"
              name="time"
              button-variant="outline-primary"
              buttons
            >
              <b-form-radio
                :value="time.id"
                v-for="time in lotteryTimes"
                :key="`time-${time.id}`"
                buttons
              >
                <span>
                  {{
                    moment(time.name, "HH:mm")
                      .locale($i18n.locale)
                      .format("h:mm a")
                  }}
                </span>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>

      <validation-observer ref="resultForm" #default="{ invalid }">
        <n-form-confirmation
          key="resultForm"
          @submit="submit"
          :form="$refs.resultForm"
          :disabled="invalid"
        >
          <b-row
            v-for="channel in channels"
            :key="`channel-${channel.post}`"
            class="result-table mb-2"
          >
            <b-col cols="4" md="3" lg="2">
              <div class="result result-head">
                {{ channel.post }}
              </div>
            </b-col>

            <b-col cols="8" md="9" lg="10">
              <b-row>
                <b-col
                  class="result-grid"
                  :md="calculateGird(channel.results.length)"
                  v-for="(result, index) in channel.results"
                  :key="`result-${channel.post}-${index}`"
                >
                  <ValidationProvider
                    :rules="`min_value:0|min:${result.digit}|max:${result.digit}`"
                    :vid="`number2-${channel.post}`"
                    :name="
                      (channel.post == 'Lo' ? index + 1 + '. ' : '') +
                      $t(`field.${result.digit}DigitNumber`)
                    "
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      :invalid-feedback="errors[0]"
                      :state="errors.length < 1"
                      :label="
                        (channel.post == 'Lo' ? index + 1 + '. ' : '') +
                        $t(`field.${result.digit}DigitNumber`)
                      "
                    >
                      <n-number-input
                        v-model="result.result"
                        :name="`number2-${channel.post}`"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                        :disabled="data.isPublish"
                        :placeholder="
                          (channel.post == 'Lo' ? index + 1 + '. ' : '') +
                          $t(`field.${result.digit}DigitNumber`)
                        "
                      ></n-number-input>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                @submit="publishResult"
                type="button"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mt-1 mr-1"
                :loading="loading"
                v-if="
                  $can('update', 'define-result') && checkCanPublishResult()
                "
              >
                {{ $t("button.publishResult") }}
              </n-button-loading>
              <n-button-loading
                @submit="cancelResult"
                type="button"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="mt-1"
                :loading="loading"
                v-if="
                  $can('update', 'define-result') && data.id && data.isPublish && data.date == today
                "
              >
                {{ $t("button.cancelResult") }}
              </n-button-loading>
              <n-button-loading
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1"
                :loading="loading"
                v-if="$can('update', 'define-result') && !data.isPublish"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BFormInput,
} from "bootstrap-vue";
import ResultMenu from "@/views/result/ResultMenu.vue";
import NNumberInput from "@/components/NNumberInput.vue";
import moment from "moment";
import NFormConfirmation from "@/components/NFormConfirmation";
import NButtonLoading from "@/components/NButtonLoading";
import NDatePicker from "@/components/NDatePicker";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import Toast from "@/libs/toast";

const LotteryRepository = Repository.get("lottery");
const LotteryResultRepository = Repository.get("lotteryResult");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormInput,
    NFormConfirmation,
    NButtonLoading,
    NNumberInput,
    ResultMenu,
    NDatePicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      today: moment().format("YYYY-MM-DD"),
      data: {
        id: null,
        lotteryId: null,
        lotteryTimeId: null,
        date: moment().format("YYYY-MM-DD"),
        posts: [],
        isPublish: false,
      },
      lotteries: [],
      lotteryTimes: [],
      results: [],
      channels: [],
      loading: false,

      twoNumber: [],
      threeNumber: [],

      moment,
    };
  },
  watch: {
    "data.date"(value) {
      if (value) {
        this.getLotteryChannel(this.data.lotteryTimeId);
      }
    },
    "data.lotteryId"(value) {
      if (value) {
        this.data.lotteryTimeId = null;
        this.getLotteryTime(value);
      }
    },
    "data.lotteryTimeId"(value) {
      if (value) {
        this.channels = [];
        this.getLotteryChannel(value);
      }
    },
  },
  mounted() {
    this.getLottery();
  },
  methods: {
    calculateGird(length) {
      if (length == 3) {
        return 4;
      } else if (length == 2) {
        return 6;
      } else if (length == 1) {
        return 12;
      } else {
        return 3;
      }
    },
    checkCanPublishResult() {
      if (!this.data.id || this.data.isPublish) {
        return false;
      }

      let isCanPublishResult = true;
      for (let channel of this.channels) {
        for (let result of channel.results) {
          if (!result.result) {
            isCanPublishResult = false;
            break;
          }
        }
      }

      return isCanPublishResult;
    },
    checkEmptyStringArray(array) {
      for (var i = 0; i < array.length; i++) {
        if (array[i] === "") return true;
      }
      return false;
    },
    getLottery() {
      LotteryRepository.listActive().then((response) => {
        const data = response?.data?.data;
        if (data) {
          if (data.length) {
            this.data.lotteryId = data[0].id;
          }
          this.lotteries = [...data];
        }
      });
    },
    getLotteryTime(lottery) {
      const index = this.lotteries.findIndex((element) => {
        return element.id == lottery;
      });

      if (index != -1) {
        let times = this.lotteries[index].times;
        this.lotteryTimes = [...times];

        if (!this.data.lotteryTimeId) {
          this.data.lotteryTimeId = this.lotteryTimes[0].id;
        }
      }
    },
    getLotteryChannel(time) {
      const index = this.lotteryTimes.findIndex((element) => {
        return element.id == time;
      });

      if (index != -1) {
        let channels = [];

        this.lotteryTimes[index].posts.forEach((element) => {
          if (element.post != "Lo") {
            let resultTwo = element.postCountTwo;
            let resultThree = element.postCountThree;

            let mostResult = resultTwo > resultThree ? resultTwo : resultThree;

            for (let i = 0; i < mostResult; i++) {
              let channel = {
                originalPost: element.post,
                post: `${element.post}${mostResult > 1 ? i + 1 : ""}`,
                results: [],
              };
              if (i < resultTwo) {
                channel.results.push({
                  digit: 2,
                  result: null,
                });
              }
              if (i < resultThree) {
                channel.results.push({
                  digit: 3,
                  result: null,
                });
              }

              channels.push(channel);
            }
          } else {
            let resultThree = element.postCountThree;
            let channel = {
              originalPost: element.post,
              post: element.post,
              results: [],
            };

            for (let i = 0; i < resultThree; i++) {
              channel.results.push({
                digit: 3,
                result: null,
              });
            }

            channels.push(channel);
          }
        });

        this.channels = [...channels];
      }

      this.show();
    },
    mapResult() {
      this.data.posts.forEach((element) => {
        if (element.post != "Lo") {
          let resultTwo = element.resultTwo.split(",");
          let resultThree = element.resultThree.split(",");

          let mostResult =
            resultTwo.length > resultThree.length
              ? resultTwo.length
              : resultThree.length;

          for (let i = 0; i < mostResult; i++) {
            const postIndex = this.channels.findIndex((channel) => {
              return (
                channel.post == `${element.post}${mostResult > 1 ? i + 1 : ""}`
              );
            });
            if (postIndex != -1) {
              if (i < resultTwo.length) {
                let k = this.channels[postIndex].results.findIndex((row) => {
                  return row.digit == 2;
                });
                if (k != -1) {
                  this.channels[postIndex].results[k].result = resultTwo[i];
                }
              }
              if (i < resultThree.length) {
                let k = this.channels[postIndex].results.findIndex((row) => {
                  return row.digit == 3;
                });
                if (k != -1) {
                  this.channels[postIndex].results[k].result = resultThree[i];
                }
              }
            }
          }
        } else {
          let resultThree = element.resultThree.split(",");
          const postIndex = this.channels.findIndex((channel) => {
            return channel.post == "Lo";
          });
          if (postIndex != -1) {
            for (let i = 0; i < resultThree.length; i++) {
              this.channels[postIndex].results[i].result = resultThree[i];
            }
          }
        }
      });

      // this.results = [...channels];
    },
    publishResult() {
      if (!this.data.id) {
        return;
      }

      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterSave"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("button.yesSaveChanges"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            LotteryResultRepository.publishResult({
              id: this.data.id,
            })
              .then((response) => {
                this.data.isPublish = true;

                Toast.fire({
                  icon: "success",
                  title: this.$t("alert.resultRelease"),
                });

                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          }
        });
    },
    cancelResult() {
      if (!this.data.id) {
        return;
      }

      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterSave"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("button.yesSaveChanges"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            LotteryResultRepository.cancelResult({
              id: this.data.id,
            })
              .then((response) => {
                this.data.isPublish = false;

                Toast.fire({
                  icon: "success",
                  title: this.$t("alert.resultRelease"),
                });

                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          }
        });
    },
    show() {
      this.loading = true;
      LotteryResultRepository.showCurrent(this.data)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = {
              ...data,
            };
            this.mapResult();
          } else {
            this.data.id = null;
            this.data.isPublish = false;
          }

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    submit() {
      this.$refs.resultForm.validate().then((success) => {
        if (success) {
          this.data.posts = [];
          this.channels.forEach((element) => {
            element.results.forEach((result) => {
              if (result.result) {
                let index = this.data.posts.findIndex((post) => {
                  return post.post == element.originalPost;
                });
                if (index !== -1) {
                  if (result.digit == 2) {
                    this.data.posts[index].resultTwo.push(result.result);
                  } else {
                    this.data.posts[index].resultThree.push(result.result);
                  }
                } else {
                  let channel = {
                    post: element.originalPost,
                    resultTwo: [],
                    resultThree: [],
                  };
                  if (result.digit == 2) {
                    channel.resultTwo = [result.result];
                  } else {
                    channel.resultThree = [result.result];
                  }
                  this.data.posts.push(channel);
                }
              }
            });
          });
          this.data.posts.map((element) => {
            element.resultTwo = element.resultTwo.length
              ? element.resultTwo.toString()
              : "";
            element.resultThree = element.resultThree.length
              ? element.resultThree.toString()
              : "";

            return element;
          });

          this.loading = true;
          LotteryResultRepository.save(this.data)
            .then((response) => {
              const data = response?.data?.data;
              if (data) {
                this.data = {
                  ...data,
                };
              }

              Toast.fire({
                icon: "success",
                title: this.$t("alert.saveSuccess"),
              });
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              if (error.response?.data?.message) {
                this.$refs.resultForm.setErrors(error.response?.data?.message);
              }
            });
        }
      });
    },
  },
  setup() {},
};
</script>